<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'drawer-help-confirmation',
  components: {
    BaseAsidePage,
    BaseButton,
    BaseIcon,
  },
}
</script>

<template>
  <base-aside-page>
    <template #iconHeader>
      <div />
    </template>
    <div
      class="mb-5 flex h-[300px] items-center justify-center bg-center bg-no-repeat pt-2.5"
      :style="{
        backgroundImage: `url('/icons/v3/empty-success.svg')`,
      }"
    >
      <div
        class="flex h-[120px] w-[120px] items-center justify-center rounded-full bg-emerald-700 text-white"
      >
        <base-icon svg="v2/custom/check" :size="75" />
      </div>
    </div>
    <div class="flex h-full flex-col justify-between">
      <p class="pt-5 text-center text-lg">
        Your message has been sent to the support team and they will respond as
        soon as possible.
      </p>
      <base-button
        type="primary"
        @click="$router.replace({ hash: '#profile-help' })"
      >
        Got it
      </base-button>
    </div>
  </base-aside-page>
</template>
